import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ExpandMore } from '@material-ui/icons';
import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import { City, Country, State } from "country-state-city";
import StorageProvider from "../../../framework/src/StorageProvider";
import { toast } from 'react-toastify';
import { validationerror } from '../../settings2/src/assets';
export const configJSON = require("./config");


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start

  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  customerType: string;
  addressType: string;
  value: number;
  open: boolean;
  selectedCountry: any;
  selectedRegion: any;
  selectedCity: any;
  customer_name: string;
  job_position: string;
  title: string;
  phone_number: string;
  alternate_phone_number: string;
  email: string;
  tags: any[];
  language: string;
  address_line1: string;
  address_line2: string;
  postal_code: string;
  website: string;
  company_name: string;
  note: string;
  addresses_attributes: any[];
  accountings_attributes: any[];
  account_sale_purchases_attributes: any[];
  contactData: any;
  salesPurchaseData: any;
  accountingData: any;
  noteAlert: boolean;
  image: any;
  tagImages: File[];
  bankDetails: any[];
  reminder: number;
  reminderCheckbox: boolean;
  token: string | null;
  saveClick: boolean;
  createClick: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export interface Option {
  value: string;
  label: string;
}

export default class VendorcreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  addCustomerApiCallId: any = "";
  postalCodeApiCallId: any = "";
  stateObj = {
    customerType: 'a',
    addressType: 'a',
    value: 0,
    open: false,
    selectedCountry: null,
    selectedRegion: null,
    selectedCity: null,
    customer_name: '',
    job_position: '',
    title: '',
    phone_number: '',
    alternate_phone_number: '',
    email: '',
    tags: [],
    language: '',
    address_line1: '',
    address_line2: '',
    postal_code: '',
    website: '',
    company_name: '',
    note: '',
    addresses_attributes: [],
    accountings_attributes: [],
    account_sale_purchases_attributes: [],
    contactData: {
      contact_name: "", title: "", job_position: "", email: '', phone_number: '', alternative_phone_number: '', address1: "", address2: "", country: "", state: "", postal_code: ''
    },
    salesPurchaseData: {},
    accountingData: {},
    noteAlert: false,
    image: null,
    tagImages: [],
    bankDetails: [{ bank_name: "", account_number: "" }],
    reminder: 1,
    reminderCheckbox: true,
    token: '',
    saveClick: false,
    createClick: false,
  };
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area Start  
    this.state = this.stateObj;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    // Customizable Area End
  }

  async componentDidMount() {
    this.setApiToken()
  }

  componentDidUpdate(prevProps: {}, prevState: S) {
    if (JSON.stringify(prevState.addresses_attributes) !== JSON.stringify(this.state.addresses_attributes)) {
      this.handleClose();
    }
  }

  setApiToken = async () => {
    const getapiToken = await StorageProvider.get("TOKEN");
    this.setState({ token: getapiToken })
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log({ errorReponse });
      runEngine.debugLog("API Message Recived", message);
      console.log('before', responseJson);
      if (responseJson?.errors) {
        this.handleErrorResponse(responseJson.errors);
      } else if ((apiRequestCallId === this.postalCodeApiCallId) && responseJson?.postal_codes) {
        this.setState({ ...this.state, postal_code: responseJson.postal_codes })
      } else if ((apiRequestCallId === this.addCustomerApiCallId) && responseJson?.data?.id) {
        this.handleDiscard();
      }
    }
    // Customizable Area End
  }

  handleErrorResponse = (error: any) => {
    let msg = configJSON.something_went_wrong;
    if (error[0] && error[0]['account']) {
      msg = error[0]['account'];
    } else if (error[0]) {
      msg = error[0];
    }
    this.showAlert(configJSON.errorTitle, msg);
  }

  handleContactErrorMessage = (type: string, show: boolean = false) => {
    const validationMap: Record<string, [boolean, string]> = {
      contact_name: [
        !this.state.contactData.contact_name?.trim(),
        'Please enter contact name.',
      ],
      contact_title: [
        !this.state.contactData.title,
        'Please select title.',
      ],
      contact_job_position: [
        !this.state.contactData.job_position,
        'Please select job position.',
      ],
      contact_email: [
        !this.state.contactData.email?.trim(),
        'Please enter email.',
      ],
      contact_phone_number: [
        !this.state.contactData.phone_number?.trim(),
        'Please enter mobile number.',
      ],
      contact_alternate_phone_number: [
        !this.state.contactData.alternate_phone_number?.trim(),
        'Please enter other number.',
      ],
      contact_address: [
        !this.state.contactData.address1?.trim(),
        'Please enter address.',
      ],
      contact_country: [
        !this.state.contactData.country,
        'Please select country.',
      ],
      contact_state: [
        !this.state.contactData.state?.trim(),
        'Please enter state.',
      ],
      contact_city: [
        !this.state.contactData.city?.trim(),
        'Please enter city.',
      ],
      contact_postal_code: [
        !this.state.contactData.postal_code?.trim(),
        'Please enter postal code.',
      ],
    };

    const [condition, message] = validationMap[type] || [];
    const flabel = condition ? message : '';

    return this.handleLabel(flabel);
  };


  handleTabErrorMessage = (type: string, show: boolean = false) => {
    if (this.state.saveClick || show) {
      const validationMap: Record<string, [any, string]> = {
        saler_name: [this.state.salesPurchaseData?.saler_name, 'Please select sales person.'],
        payment_terms: [this.state.salesPurchaseData?.sale_payment_terms, 'Please select payment terms.'],
        price_list: [this.state.salesPurchaseData?.pricelist, 'Please select pricelist.'],
        delivery_method: [this.state.salesPurchaseData?.delivery_method, 'Please select delivery method.'],
        product_code: [this.state.salesPurchaseData?.product_code, 'Please select product code.'],
        purchase_payment_terms: [this.state.salesPurchaseData?.puchase_payment_terms, 'Please select payment terms.'],
        payment_method: [this.state.salesPurchaseData?.payment_method, 'Please select payment method.'],
        supplier_currency: [this.state.salesPurchaseData?.supplier_currency, 'Please select supplier currency.'],
        reminder: [this.state.reminder, 'Please enter receipt reminder.'],
        account_reievable: [this.state.accountingData?.receivable, 'Please select account receivable.'],
        account_payable: [this.state.accountingData?.payable, 'Please select account payable.'],
        tag_images: [this.state.tagImages.length, 'Please select tags.'],
      };

      const [fieldValue, errorMessage] = validationMap[type] || [];
      const flabel = !fieldValue && errorMessage;

      return this.handleLabel(flabel);
    } else {
      return '';
    }
  };


  handleLabel = (flabel: any) => {
    return <>
      {
        flabel ? this.handleErrorLabel(flabel) : flabel
      }
    </>
  }

  handleErrorMessage = (type: string, show: boolean = false) => {
    if (this.state.saveClick || show) {
      const validationMap: Record<string, [boolean, string]> = {
        customer_name: [!this.state.customer_name.trim(), 'Please enter valid customer name.'],
        job_position: [!this.state.job_position, 'Please select valid job position.'],
        title: [!this.state.title, 'Please select valid title.'],
        phone_number: [!this.state.phone_number.trim(), 'Please enter valid mobile number.'],
        alternate_phone_number: [!this.state.alternate_phone_number.trim(), 'Please enter valid alternate mobile number.'],
        email: [!this.state.email.trim(), 'Please enter valid email.'],
        tags: [!this.state.tags.length, 'Please select valid tags.'],
        language: [!this.state.language, 'Please select valid language.'],
        website: [!this.state.website.trim(), 'Please enter valid website.'],
        address1: [!this.state.address_line1.trim(), 'Please enter valid address.'],
        selectedCountry: [!this.state.selectedCountry, 'Please select valid country.'],
        selectedRegion: [!this.state.selectedRegion, 'Please select valid state.'],
        selectedCity: [!this.state.selectedCity, 'Please select valid city.'],
        company_name: [!this.state.company_name.trim(), 'Please enter valid company name.'],
      };

      const mobileErrors = ['1', '2', '3', '4'];
      const emailErrors = ['email1', 'email2'];

      let flabel: string = '';

      if (validationMap[type]) {
        const [condition, message] = validationMap[type];
        flabel = condition ? message : '';
      } else if (mobileErrors.includes(type)) {
        flabel = configJSON.errorMobileLengthNotValid;
      } else if (emailErrors.includes(type)) {
        flabel = configJSON.errorEmailNotValid;
      }

      return this.handleLabel(flabel);
    } else {
      return '';
    }
  };



  errorTabTextClass = (field: any, value: any) => {
    if (this.handleTabErrorMessage(field) && !value) {
      return 'error-text1';
    } else {
      return 'main-color';
    }
  }

  errorTextClass = (field: any, value: any) => {
    if (this.handleErrorMessage(field) && !value) {
      return 'error-text1';
    } else {
      return 'main-color';
    }
  }

  errorContactTextClass = (field: any, value: any) => {
    if (this.state.createClick && this.handleContactErrorMessage(field) && !value) {
      return 'error-text1';
    } else {
      return 'main-color';
    }
  }

  errorSelectFocusClass = (field: any, value: any) => {
    if (this.state.createClick && this.handleContactErrorMessage(field) && !value) {
      return 'error-focus';
    } else {
      return '';
    }
  }

  errorContactFocusClass = (field: any, value: any) => {
    if (this.state.createClick && this.handleContactErrorMessage(field) && !value) {
      return 'error-focus1';
    } else {
      return '';
    }
  }

  errorFocusClass = (field: any, value: any) => {
    if (this.handleErrorMessage(field) && !value) {
      return 'error-focus';
    } else {
      return '';
    }
  }

  errorTabFocusClass = (field: any, value: any) => {
    if (this.handleTabErrorMessage(field) && !value) {
      return 'error-focus';
    } else {
      return '';
    }
  }

  errorTagsClass = (field: any) => {
    if (this.handleErrorMessage(field) && !this.state.tags.length) {
      return 'tags-error-focus';
    } else {
      return '';
    }
  }

  mainErrorBox = () => {
    return <Box className={`flex error-main-box ${!(this.state.saveClick && ((!this.checkAddCustomerValidation()) || (!this.state.addresses_attributes.length))) && 'display-none'}`}>
      <img src={validationerror} />
      <Typography className='error-main-text'>Some of the fields are incorrect or invalid. Please enter the valid details to continue.</Typography>
    </Box>
  }

  handleErrorLabel = (flabel: any) => {
    return <Box className='flex relative' >
      <img className='error-text-img' src={validationerror} />
      <Typography className='error-text'>{flabel}</Typography>
    </Box>
  }

  handleWebsiteError = (msg: any = '') => {
    return <Box className='flex relative' >
      <img className='error-text-img' src={validationerror} />
      <Typography className='error-text'>{msg == 'add_address' ? configJSON.errorAddOneContact : 'Please enter valid website.'}</Typography>
    </Box>
  }

  handleRemoveFile = (index: any) => {
    console.log('remove file ', this.state.tagImages, index)
    const filteredPeople: any = this.state.tagImages.length > 0 ? Array.from(this.state.tagImages).filter((item: any, indx: number) => indx !== index) : [];
    console.log('filteredPeople ', filteredPeople)
    this.setState({ ...this.state, tagImages: filteredPeople })
  }

  removeColumn = (index: any) => {
    this.setState({ ...this.state, bankDetails: this.state.bankDetails.filter((i: any, indx: number) => indx != index) })
  }

  addAnotherColumn = () => {
    this.setState({
      ...this.state,
      bankDetails: this.state.bankDetails.concat({
        bank_name: "",
        account_number: ""
      })
    })
  }

  handleBankDetails = (e: any, index: number, field: string) => {

    let newArr = JSON.parse(JSON.stringify(this.state.bankDetails));
    const editArr: any = newArr.filter((i: any, indx: number) => indx == index)
    //console.log(editArr)
    if (editArr?.length > 0) {
      editArr[0][field] = e.target?.value;
    }
    this.setState({ ...this.state, bankDetails: newArr })
  }

  isStringNullOrBlank(str: string) {
    return str?.trim() === null || str?.trim().length === 0;
  }

  handleDiscard = () => {
    this.props.navigation.goBack();
    this.setState(this.stateObj);
  }

  handleTagImages = (event: any) => {
    const inputFiles: any = event.target?.files;

    if (inputFiles) {
      const newFiles = Array.from(inputFiles);
      this.setState((prevState: any) => ({
        tagImages: [...prevState.tagImages, ...newFiles],
      }));
    }


  }

  noteValidation = (note: any) => {
    if (note.length > 500) {
      this.setState({ ...this.state, noteAlert: true });
    } else {
      this.setState({ ...this.state, note: note, noteAlert: false });

    }
  }

  handleMobile = (field: any, mob: any) => {
    switch (field) {
      case 'mobile1':
        this.setState({ ...this.state, phone_number: mob });
        break;
      case 'mobile3':
        this.setState({
          ...this.state,
          contactData: { ...this.state.contactData, phone_number: mob },
        });
        break;
      case 'mobile4':
        this.setState({
          ...this.state,
          contactData: { ...this.state.contactData, alternate_phone_number: mob },
        });
        break;
      default:
        this.setState({ ...this.state, alternate_phone_number: mob });
        break;
    }
  
    return field == 'mobile1' || field == 'mobile3' || field == 'mobile4';
  };
  

  checkMobileLength = (mob: any, type: string) => {
    if (mob && mob.length < 10) {

      return false;
    }

    return true;
  }

  isMobileNumberValid = (mob: any, field: string) => {

    let mobreg = /^\d{0,15}$/;
    if (!mob) {
      field == 'mobile1' && this.setState({ ...this.state, phone_number: '' });
      field == 'mobile2' && this.setState({ ...this.state, alternate_phone_number: '' });
      field == 'mobile3' && this.setState({ ...this.state, contactData: { ...this.state.contactData, phone_number: '' } });
      field == 'mobile4' && this.setState({ ...this.state, contactData: { ...this.state.contactData, alternate_phone_number: '' } });
      return false;
    }
    if (mobreg.test(mob) === false && (field == 'mobile1' || field == 'mobile2')) {
      return false;
    }
    if (mobreg.test(mob) === false && (field == 'mobile3' || field == 'mobile4')) {

      return false;
    }
    this.handleMobile(field, mob);
  }

  expandMore = () => {
    return <ExpandMore />;
  }

  isValidEmail = (Email: string, field: string) => {
    let reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (reg.test(Email) === false && Email?.trim()) {

      return false;
    } else {

      return true;
    }
  };

  onTagsChange = (event: any, values: any) => {
    this.setState({
      ...this.state,
      tags: values
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
      console.log(this.state.tags);
    });
  }

  handleContact = () => {

    if (this.handleContactValidation()) {
      let data: any = {
        contact_name: this.state.contactData.contact_name,
        postal_code: this.state.contactData.postal_code,
        phone_number: this.state.contactData.phone_number,
        email: this.state.contactData.email,
        title: this.state.contactData.title,
        job_position: this.state.contactData.job_position,
        address: this.state.contactData.address1 + "@" + this.state.contactData.address2,
        state: this.state.contactData.state,
        city: this.state.contactData.city,
        country: this.state.contactData.country,
        note: this.state.contactData.note,
        alternate_phone_number: this.state.contactData.alternate_phone_number
      }
      this.setState({
        ...this.state,
        addresses_attributes: this.state.addresses_attributes.concat(data)
      });
    }

  }
  handleCountryChange = (event: any) => {
    const selectedCountry = event.target?.value as string;
    this.setState({
      ...this.state,
      selectedCountry,
      selectedRegion: '',
      selectedCity: '',
    });
  };

  handleRegionChange = (event: any) => {
    const selectedRegion = event.target?.value as string;
    this.setState({
      ...this.state,
      selectedRegion,
      selectedCity: '',
    });
  };

  handleCityChange = (event: any) => {
    const selectedCity = event.target?.value as string;
    this.setState({
      ...this.state,
      selectedCity,
    });
    this.fetchPostalCode(selectedCity.split('@')[1], selectedCity.split('@')[2])

  };

  SelectCity = (selectedRegion: any, selectedCity: any, selectedCountry: any) => {
    return <>
      <Select
        IconComponent={this.expandMore}
        inputProps={{
          'aria-label': 'custom select',
        }}
        data-test-id="cityInput"
        value={selectedCity}
        onChange={this.handleCityChange}
        label="Select"
        disableUnderline
        className={`width-100 ${this.errorFocusClass('selectedCity', this.state.selectedCity)}`}

      >
        {(selectedRegion ? City.getCitiesOfState(selectedCountry, selectedRegion) : [])?.length > 0 && (selectedRegion ? City.getCitiesOfState(selectedCountry, selectedRegion) : []).map((city: any, index: number) => (
          <MenuItem className='select-menu-item' key={index} value={city.name + '@' + city.latitude + '@' + city.longitude}>
            {city.name}
          </MenuItem>
        ))}

      </Select>
    </>
  }

  SelectState = (selectedRegion: any, regions: any) => {
    return <>
      <Select
        IconComponent={this.expandMore}
        inputProps={{
          'aria-label': 'custom select',
        }}
        data-test-id="stateInput"
        value={selectedRegion}
        onChange={this.handleRegionChange}
        label="Select"
        disableUnderline
        className={`width-100 ${this.errorFocusClass('selectedRegion', this.state.selectedRegion)}`}

      >
        {regions?.length > 0 && regions.map((region: any) => (
          <MenuItem className='select-menu-item' key={region.isoCode} value={region.isoCode}>
            {region.name}
          </MenuItem>
        ))}

      </Select>

    </>
  }

  fetchPostalCode(lat: any, long: any) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {

    };

    this.postalCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/postal_code?latitude=${lat}&longitude=${long}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  TabPanel: React.FC<TabPanelProps> = ({ children, index, value }) => {
    return <div hidden={value !== index}>{children}</div>;
  };

  handleCustomerType = (event: any) => {
    this.setState({ ...this.state, customerType: event.target.value });
  };

  handleImageChange = (e: any) => {
    const file = e?.target?.files && e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        toast.error("Please select a valid image file");
        return;
      }
      this.setState({ ...this.state, image: file });
    }
  }

  handle1Validation = () => {
    if (this.isValidEmail(this.state.contactData.email, 'email2') &&
      this.state.contactData.phone_number &&
      (!this.isStringNullOrBlank(this.state.contactData.phone_number)) &&
      this.checkMobileLength(this.state.contactData.phone_number, '2') &&
      this.state.contactData.alternate_phone_number &&
      (!this.isStringNullOrBlank(this.state.contactData.alternate_phone_number)) &&
      this.checkMobileLength(this.state.contactData.alternate_phone_number, '2')) {
      return true;
    }
    return false;
  }
  handleContactValidation = (): boolean => {
    if (this.state.addressType == 'a' &&
      this.state.contactData.contact_name && (!this.isStringNullOrBlank(this.state.contactData.contact_name)) &&
      this.state.contactData.title && (!this.isStringNullOrBlank(this.state.contactData.title)) &&
      this.state.contactData.job_position && (!this.isStringNullOrBlank(this.state.contactData.job_position)) &&
      this.state.contactData.email && (!this.isStringNullOrBlank(this.state.contactData.email)) &&
      this.state.contactData.phone_number && (!this.isStringNullOrBlank(this.state.contactData.phone_number)) &&
      this.state.contactData.alternate_phone_number && (!this.isStringNullOrBlank(this.state.contactData.alternate_phone_number))
    ) {
      return this.handle1Validation()
    } else if (this.state.addressType == 'b' &&
      this.state.contactData.address1 && (!this.isStringNullOrBlank(this.state.contactData.address1)) &&
      this.state.contactData.country && (!this.isStringNullOrBlank(this.state.contactData.country)) &&
      this.state.contactData.state && (!this.isStringNullOrBlank(this.state.contactData.state)) &&
      this.state.contactData.city && (!this.isStringNullOrBlank(this.state.contactData.city)) &&
      this.state.contactData.postal_code && (!this.isStringNullOrBlank(this.state.contactData.postal_code))
    ) {
      return true;
    } else {
      this.setState({ ...this.state, createClick: true });
      return false;
    }
  }

  handleAddressType = (event: React.ChangeEvent<HTMLInputElement>) => {


    this.setState({ ...this.state, addressType: event.target.value });


  };


  imageRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  tagImagesRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  options: Option[] = [
    { value: '215.00%', label: '215.00%' },
    { value: '220.00%', label: '220.00%' }
    // Add more options as needed
  ];

  handleMultiSelect = (value: any, name: any) => {
    this.setState({ ...this.state, tags: value })
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ ...this.state, value: newValue });
  };
  handleOpen = () => {
    this.setState({ ...this.state, open: true });
  };

  handleReminder = (e: any) => {
    const numericValue = e.target.value.replace(/\D/g, '');
    this.setState({ ...this.state, reminder: numericValue, salesPurchaseData: { ...this.state.salesPurchaseData, receipt_reminder: numericValue } });
  }

  websiteValidation = (website: any) => {
    const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,5}(\/.*)?$/i;
    return urlPattern.test(website) || website === "";
  };
  
  handleClose = () => {
    this.setState({
      ...this.state,
      createClick: false,
      addressType: 'a',
      open: false,
      contactData: {}
    })
  };

  addCustomerData = () => {
    const countries = Country.getAllCountries();
    const stat = State.getStatesOfCountry(this.state.selectedCountry)
    this.setState({ ...this.state, saveClick: false })
    let formdata: any = new FormData();
    formdata.append("data[image]", this.state.image);
    this.state.tagImages.length > 0 && this.state.tagImages.forEach((item: any, index: number) => {
      formdata.append(`data[accounting_attributes][accounting_tags][]`, item);
    })
    formdata.append("data[first_name]", this.state.customer_name.trim());
    formdata.append("data[user_type]", 'vendor');
    formdata.append("data[email]", this.state.email);
    formdata.append("data[phone_number]", this.state.phone_number);
    formdata.append("data[full_phone_number]", this.state.phone_number);
    formdata.append("data[alternate_phone_number]", this.state.alternate_phone_number);
    formdata.append("data[address]", this.state.address_line1 + "@" + this.state.address_line2);
    formdata.append("data[city]", this.state.selectedCity);
    formdata.append("data[state]", stat.filter((c: any) => c.isoCode == this.state.selectedRegion)[0]?.name);
    formdata.append("data[country]", countries.filter((c: any) => c.isoCode == this.state.selectedCountry)[0]?.name);
    formdata.append("data[postal_code]", this.state.postal_code);
    formdata.append("data[title]", this.state.title);
    formdata.append("data[job_position]", this.state.job_position);

    formdata.append("data[website]", this.state.website);
    formdata.append("data[language]", this.state.language);
    formdata.append("data[company_name]", this.state.company_name);
    formdata.append("data[note]", this.state.note);
    formdata.append("data[internal_note_attributes][internal_note]", this.state.note);
    formdata.append(`data[tags][]`, this.state.tags);
    this.state.addresses_attributes.length > 0 && this.state.addresses_attributes.forEach((item: any, index: number) => {
      Object.keys(item).forEach((key) => {
        formdata.append(`data[addresses_attributes][${index}][${key}]`, item[key]);
      });
    })
    Object.keys(this.state.accountingData).forEach((key) => {
      if (!(key == 'bank_name' || key == 'account_number')) {
        formdata.append(`data[accounting_attributes][${key}]`, this.state.accountingData[key]);
      }
    });
    this.state.bankDetails.length > 0 && this.state.bankDetails.forEach((item: any, index: number) => {
      Object.keys(item).forEach((key: any) => {
        formdata.append(`data[account_details_attributes][${index}][${key}]`, (key == 'bank_name' && (!item[key])) ? 'Bank Muscat' : item[key]);
      });
    })

    Object.keys(this.state.salesPurchaseData).forEach((key) => {
      formdata.append(`data[account_sale_purchases_attributes][${key}]`, this.state.salesPurchaseData[key]);
    });
    formdata.append("data[customer_type]", this.state.customerType == 'a' ? 'individual' : 'company');


    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCustomerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saveVendorUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  checkEmailWebsite = () => {
    if ((!this.isValidEmail(this.state.email, 'email1')) ||
      (!this.checkMobileLength(this.state.phone_number, '1')) ||
      (!this.checkMobileLength(this.state.alternate_phone_number, '1'))) {
      return false;
    }
    if (!(this.websiteValidation(this.state.website))) {
      return false;
    }

  }

  checkAddCustomerValidation = () => {
    if (
      this.isStringNullOrBlank(this.state.customer_name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phone_number) ||
      this.isStringNullOrBlank(this.state.alternate_phone_number) ||
      this.isStringNullOrBlank(this.state.language) ||
      this.isStringNullOrBlank(this.state.address_line1) ||
      this.isStringNullOrBlank(this.state.selectedCountry) ||
      this.isStringNullOrBlank(this.state.selectedRegion) ||
      this.isStringNullOrBlank(this.state.selectedCity) ||
      this.isStringNullOrBlank(this.state.website) ||
      this.isStringNullOrBlank(this.state.company_name) ||
      (!this.state.tags.length) ||
      (Object.keys(this.state.salesPurchaseData).length < 9) ||
      (Object.keys(this.state.accountingData).length < 2) ||
      this.state.tagImages.length == 0 || (!this.state.reminder) || (!(this.websiteValidation(this.state.website))) || (!(this.isValidEmail(this.state.email, 'email1')))
    ) {
      this.checkEmailWebsite()
      return false;

    } else if (this.state.customerType == 'a' && (this.isStringNullOrBlank(this.state.job_position) || this.isStringNullOrBlank(this.state.title))) {

      return false;
    } else if (this.state.addresses_attributes.length == 0) {

      return false;
    }
    return true;
  }

  addCustomer = () => {
    this.setState({ ...this.state, saveClick: true })
    if (!this.checkAddCustomerValidation()) {
      return false;
    } else {

      this.addCustomerData();

    }
  }

  navigateToCustomerListPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Cfcustomersaleslistview"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

}