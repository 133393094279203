Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfpurchases";
exports.labelBodyText = "cfpurchases Body";

exports.customer = "Vendor";
exports.new = "New";
exports.units = "Units";
exports.purchase = "Purchase";
exports.vendor_bills = "Vendor Bills";
exports.invoiced = "Invoiced";
exports.meetings = "Meetings";
exports.activity = "Activity";
exports.customer_type = "Vendor Type";
exports.individual = "Individual";
exports.company = "Company";
exports.customer_name = "Customer Name";
exports.job_position = "Job Position";
exports.title = "Title";
exports.mobile_number = "Mobile Number";
exports.alternative_number = "Alternative Number";
exports.email_address = "Email Address";
exports.tags = "Tags";
exports.language = "Language";
exports.address_line1 = "Address (line 1)";
exports.address_line2 = "Address (line 2)";
exports.country = "Country";
exports.state = "State";
exports.city = "City";
exports.postal_code = "Postal Code";
exports.website = "Website";
exports.company_name = "Company Name";
exports.contact_address = "Contact & Address";
exports.sales_purchase = "Sales & Purchase";
exports.accounting = "Accounting";
exports.internal_notes = "Internal notes";
exports.internal_notes1 = "Internal Notes";
exports.add = "ADD";
exports.add_more = "ADD MORE";
exports.phone = "Phone";
exports.sales = "Sales";
exports.sales_person = "Sales Person";
exports.payment_terms = "Payment Terms";
exports.pricelist = "Pricelist";
exports.delivery_method = "Delivery Method";
exports.point_of_sale = "Point of sale";
exports.product_code = "Product code";
exports.payment_method = "Payment Method";
exports.receipt_reminder = "Receipt Reminder";
exports.supplier_currency = "Supplier Currency";
exports.account_details = "Account Details";
exports.bank = "Bank";
exports.account_number = "Account Number";
exports.account_entries = "Account Entries";
exports.account_recievables = "Account Recievables";
exports.account_payable = "Account Payable";
exports.general_information = "General Information";
exports.drop_files = "Drop Files to attach or browse";
exports.internal_notes_placeholder = "This note only for internal purposes...";
exports.max_500 = "Max 500 characters";
exports.max_8 = "max 8 char's";
exports.create_contact = "Create Contact";
exports.contact = "Contact";
exports.other_address = "Other Address";
exports.contact_name = "Contact Name";
exports.discard = "Discard";
exports.save = "Save";
exports.save_next = "Save & Next";
exports.saveVendorUrl = "account_block/accounts/create_customer"
exports.vendorListingUrl = "account_block/accounts/get_customers_list"
// Customizable Area End