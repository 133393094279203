//@ts-nocheck
import React, { Component, ReactNode } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { withStyles } from "@material-ui/core/styles";
import { Link, NavLink } from "react-router-dom";
import { Box } from "@material-ui/core";

import {
  home,
  sales,
  inventory,
  terminal,
  purcharge,
  setting,
  accounting,
  contacts,
  homeBlue,
  salesBlue,
  inventoryBlue,
  terminalBlue,
  purchaseBlue,
  settingBlue,
  accountingBlue,
  contactsBlue,
  logout,
  logo,
  hamburger,
} from "./assets";
const Images = require("./assets");

interface MenuItem {
  name: string;
  icon?: any;
  activeIcon?:any;
  url?: string;
  parent?: boolean;
  level?:number;
  children?: MenuItem[];
}

interface MenuBarState {
  [name: string]: boolean;
}

interface props {
  classes?: any;
}

interface S{
    isOpen: boolean,
    activeRoute:string[],
}

const menuList: { data: MenuItem[] } = {
  data: [
    {
      name: "Home",
      icon: home,
      activeIcon: homeBlue,
      parent: true,
      level: 1,
      children: [
        {
          name: "Reports",
          url: "",
          level: 2,
        },
        {
          name: "Customer",
          url: "",
          level: 2,
        },
        {
          name: "Product",
          url: "",
          level: 2,
        },
      ],
    },
    {
      name: "Sales",
      icon: sales,
      activeIcon: salesBlue,
      parent: true,
      level: 1,
      children: [
        {
          name: "Reports",
          url: "",
          level: 2,
        },
        {
          name: "Customer",
          url: "/cfcustomersaleslistview",
          level: 2,
        },
        {
          name: "Product",
          url: "/itemavailability",
          level: 2,
        },
        {
          name: "Order Management",
          children: [
            {
              name: "List of Orders",
              url: "/SalesOrder",
              level: 3,
            },
            {
              name: "Quotations",
              url: "/Ordercreation2",
              level: 3,
            },
          ],
        },
        {
          name: "Configuration",
          children: [
            {
              name: "Settings",
              url: "/cfCustomerSettingTabs",
              level: 3,
            },
            {
              name: "Shipping Methods",
              url: "/SalesconfigurationShippingmethod",
              level: 3,
            },
            {
              name: "UoM Categories",
              url: "/UomCategorieslistview",
              level: 3,
            },
            {
              name: "Unit of Measure",
              url: "/UnitOfMeasurelistview",
              level: 3,
            },
          ],
        },
      ],
    },
    {
      name: "POS",
      icon: terminal,
      activeIcon:terminalBlue,
      parent: true,
      level: 1,
      children: [
        {
          name: "Point of Sale",
          url: "/Cfposscreen4",
          level: 2,
        },
        {
          name: "Orders",
          children: [
            {
              name: "List of Orders",
              url: "/PosOrderListing",
              level: 3,
            },
            {
              name: "Sessions",
              url: "",
              level: 3,
            },
            {
              name: "Payments",
              url: "",
              level: 3,
            },
            {
              name: "Customers",
              url: "",
              level: 3,
            },
          ],
        },
        {
          name: "Reports",
          children: [
            
          ],
        },
        {
          name: "Product",
          children: [
            
          ],
        },
        {
          name: "Configuration",
          children: [
            {
              name: "Settings",
              url: "",
              level: 3,
            },
            {
              name: "Point of Sale",
              url: "",
              level: 3,
            },
            {
              name: "Payment Method",
              url: "",
              level: 3,
            },
            {
              name: "Product Category",
              url: "/PosProductCategoriesView",
              level: 3,
            },
          ],
        },
      ]
    },
    {
      name: "Purchase",
      icon: purcharge,
      activeIcon:purchaseBlue,
      parent: true,
      level: 1,
      children: [
        {
          name: "Reports",
          url: "",
          level: 2,
        },
        {
          name: "Customer",
          url: "",
          level: 2,
        },
        {
          name: "Product",
          url: "",
          level: 2,
        },
      ],
    },
    {
      name: "Inventory",
      icon: inventory,
      activeIcon:inventoryBlue,
      parent: true,
      level: 1,
      children: [
        {
          name: "Reports",
          url: "",
          level: 2,
        },
        {
          name: "Customer",
          url: "",
          level: 2,
        },
        {
          name: "Product",
          url: "",
          level: 2,
        },
      ],
    },
    {
      name: "Accounting",
      icon: accounting,
      activeIcon:accountingBlue,
      parent: true,
      level: 1,
      children: [
        {
          name: "Reports",
          url: "",
          level: 2,
        },
        {
          name: "Customer",
          url: "",
          level: 2,
        },
        {
          name: "Product",
          url: "",
          level: 2,
        },
        {
          name: "Taxes",
          url: "/TaxesListing",
          level: 2,
        },
      ],
    },
    {
      name: "Contacts",
      icon: contacts,
      activeIcon:contactsBlue,
      parent: true,
      level: 1,
      children: [
        {
          name: "Contacts",
          url: "",
          level: 2,
        },
        {
          name: "Configuration",
          children: [
            {
              name: "Banks",
              url: "/BanksListView",
              level: 3,
            },
            {
              name: "Bank Accounts",
              url: "/BankAccountListing",
              level: 3,
            },
          ],
        },
      ]
    },
    {
      name: "Settings",
      icon: setting,
      activeIcon:settingBlue,
      parent: true,
      level: 1,
      children: [
        {
          name: "Users",
          url: "/SettingsUserListView",
          level: 2,
        },
        {
          name: "Access Rights",
          url: "/SettingsAccessRightsListView",
          level: 2,
        },
        {
          name: "Companies",
          url: "/CompaniesListView",
          level: 2,
        },
      ],
    },
  ],
};

const styles = {
  controller: {
    "& .MuiDrawer-paper": {
      position: "inherit",
      transition: "0.5s",
      overflow: "visible",
    },
  },
  openDrawer: {
    width: "260px",
    background: "rgb(1, 99, 151)",
  },
  closeDrawer: {
    width: "60px",
    background: "rgb(1, 99, 151)",
  },
  links: {
    width: "100%",
    textDecoration: "none",
  },
  menuHeader: {
    paddingLeft: "10px",
    color: "#fff",
  },
  menuHeading:{
    justifyContent: "space-between"
  },
  itemStyle: {
    gap: "10px",
    color: "#fff",
    margin: "0",
  },
  subMenu: {
    color: "#fff",
    position: "relative",
    transform: " translateX(46px)",
    "&::before": {
      content: '" "',
      display: "block",
      height: "5px",
      width: "5px",
      backgroundColor: "#fff",
      position: "absolute",
      left: "-13px",
      top: "8px",
      borderRadius: "5px",
    },
  },
  subOptiontranslate: {
    color: "#fff",
    position: "relative",
    transform: " translateX(75px)",
    "&::before": {
      content: '" "',
      display: "block",
      height: "5px",
      width: "5px",
      backgroundColor: "#fff",
      position: "absolute",
      left: "-13px",
      top: "8px",
      borderRadius: "5px",
    },
  },
  subMenuActive: {
    "& span": {
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "transparent"
    },
    "& .MuiTouchRipple-root:hover": {
      backgroundColor: "transparent !important"
    }
  },
  selectedMenuItem: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  imageIcon: {
    height: "24px",
    width: "24px",
  },
  itemHolder: {
    "& .MuiListItem-root": {
      padding: "10px",
    },
  },
  navbarContainer: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position:"sticky",
    top:0,
    left:0,
  },
  signoutBtn: {
    display: "flex",
    flex: 0,
    alignItems: "center",
    padding: "10px",
    gap: "10px",
    paddingLeft:"15px",
  },
  navigationStyle: {
    "& span": {
      fontSize: "10px",
      color: "white",
      padding: "10px",
    },
  },
  active: {
    "& div": {
      "& span": {
        fontWeight: "bold",
      },
    },
    "&:hover": {
      backgroundColor: "transparent"
    },
    "& .MuiTouchRipple-root:hover": {
      backgroundColor: "transparent !important"
    }
  },
  selectedMenuItemText: {
    color: "#2A6396",
    "& span": {
      fontWeight: "bold",
    },
    "&:hover": {
      color: "#2A6396",
      "& span": {
        fontWeight: "bold",
      },
    },
  },
  colorIcon: {
    color: "#004998",
  },
  hamburger:{
    marginRight:"15px",
    paddingBottom:"40px" ,
  },
  drawerIcon:{
    "&:hover": {
        backgroundColor: "transparent"
    }
  },
  closedDrawerHamburger:{ 
    color: "white" , paddingLeft:"4px" 
  },
  innerOption: {
    background: 'none',
    "&:hover": {
      backgroundColor: "transparent"
    },
    "& .MuiTouchRipple-root:hover": {
      backgroundColor: "transparent !important"
    }
  }
};

class Sidebar extends Component<props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
        isOpen: true,
        activeRoute:[],
    }
  }

  componentDidMount(): void {
    const pathName:string=window.location.pathname;
    let searchResult = this.findParentsAndGrandparents(
      pathName,
      menuList.data
    ).flat();
    this.setState({
      activeRoute: searchResult,
    });
    searchResult.forEach((item: string) => {
      this.handleClick(item);
    });
  }

  findParentsAndGrandparents(inputUrl:string, data :any, parents:any = [], results:any = []) {
    for (const item of data) {
      if (item.url === inputUrl) {
        if (parents.length > 0) {
          results.push([...parents]);
        }
      }

      if (item.children && item.children.length > 0) {
        this.findParentsAndGrandparents(
          inputUrl,
          item.children,
          [...parents, item.name],
          results
        );
      }
    }

    return results;
  }

  toggleDrawer = () => {
    this.setState((prev: any)=>( { isOpen: !prev.isOpen}) );
  };
  handleClick(item: string) {
    //@ts-ignore
    this.setState((prevState:S) => ({ [item]: !prevState[item] }));
  }


  handler(children: MenuItem[] | undefined): ReactNode[] | null {
    const { classes } = this.props;
    const { state } = this;
    if (!children) return null;
    return children.map((subOption:MenuItem) => {
      if (!subOption.children) {
        return (
          <div key={subOption.name}>
            <ListItem
              button
              key={subOption.name}
              className={classes.innerOption}
              style={{
                paddingLeft: `${subOption.level && subOption.level > 2 && subOption?.level * 8}px`,
                width:"auto",
              }}
            >
              <NavLink
                to={subOption.url || ""}
                className={classes.links}
                exact={true}
                activeClassName={classes.active}
              >
                <ListItemText
                  primary={subOption.name}
                  className={`${classes.subMenu} `}
                />
              </NavLink>
            </ListItem>
          </div>
        );
      }
      return (
        <div key={subOption.name}>
          {this.state.isOpen ? (
            <Box className={classes.itemHolder}>
              <ListItem
                button
                onClick={() => this.handleClick(subOption.name)}
                className={`${classes.itemStyle} 
                ${state[subOption.name] &&
                  subOption.parent &&
                  classes.selectedMenuItem}`}
              >
                {}
                {subOption.icon && (
                  <img src={this.state[subOption.name]?subOption.activeIcon:subOption.icon} className={classes.imageIcon} />
                )}
                {subOption.icon ? (
                  <ListItemText
                    primary={subOption.name}
                    className={`${classes.itemStyle} 
                    ${state[subOption.name] && classes.selectedMenuItemText}`}
                  />
                ) : (
                  <ListItemText
                    primary={subOption.name}
                    className={`${classes.subMenu} ${state[subOption.name] &&
                      classes.subMenuActive}`}
                  />
                )}

                {state[subOption.name] ? (
                  <IconExpandLess
                    className={
                      state[subOption.name] &&
                      subOption.parent &&
                      classes.colorIcon
                    }
                  />
                ) : (
                  <IconExpandMore
                    className={
                      state[subOption.name] &&
                      subOption.parent &&
                      classes.colorIcon
                    }
                  />
                )}
              </ListItem>
              <Collapse in={state[subOption.name]} timeout="auto" unmountOnExit>
                {this.handler(subOption.children)}
              </Collapse>
            </Box>
          ) : (
            <ListItem>
              <img src={subOption.icon} />
            </ListItem>
          )}
        </div>
      );
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.controller}>
        <Drawer
          variant="persistent"
          anchor="left"
          open={true}
          classes={{
            paper: this.state.isOpen ? classes.openDrawer : classes.closeDrawer,
          }}
        >
          <div className={classes.navbarContainer}>
            <List>
              <ListItem
                key="menuHeading"
                disableGutters
                className={classes.menuHeading}
              >
                {this.state.isOpen ? (
                  <Box>
                    <img src={logo} />
                    <ListItemText
                      primary={"NAVIGATION"}
                      className={classes.navigationStyle}
                    />
                  </Box>
                ) : (
                  ""
                )}
                <IconButton onClick={this.toggleDrawer} disableFocusRipple disableTouchRipple disableRipple className={classes.drawerIcon}>
                  {this.state.isOpen ? (
                    <img src={hamburger} className={classes.hamburger}/>
                  ) : (
                    <MenuIcon className={classes.closedDrawerHamburger}/>
                  )}
                </IconButton>
              </ListItem>
              {this.handler(menuList.data)}
            </List>
            <Box className={classes.signoutBtn}>
              <img src={logout} />
              {this.state.isOpen ? (
                <ListItemText
                  primary={"Sign out"}
                  className={classes.itemStyle}
                />
              ) : (
                ""
              )}
            </Box>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles)(Sidebar);