// Customizable Area Start
import React from 'react';
import { delrow, paginationarrow } from './assets';
import { Checkbox, CircularProgress, Grid, TableCell, TableRow, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Message } from '../../../framework/src/Message';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { IBlock } from '../../../framework/src/IBlock';
import { runEngine } from '../../../framework/src/RunEngine';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import StorageProvider from '../../../framework/src/StorageProvider';

export const configJSON = require("./config");

// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes?: any;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    showdata: any[];
    groupbyResponse: any[];
    searchValue: string;
    groupValue: string;
    groupByvalue: string;
    loading: boolean;
    searchKeyword: string;
    totalCounts: number;
    totalPage: number;
    activeGroup: string;
    activeFilter: number;
    activeFilterArr: any[];
    remainingItems: number;
    isLoadMore: boolean;
    filterLabel: string;
    deleteTableId: string;
    globalCheck: boolean;
    checkedArr: any[];
    active: any[];
    deleteDialog: boolean;
    deleteDialogTable: boolean;
    showFilter: boolean;
    showGroup: boolean;
    showFav: boolean;
    showColumn: boolean
    showCurrentSearch: boolean;
    showAddToDashboard: boolean;
    showSettings: boolean;
    searchArray: any[];
    defaultView: boolean;
    loadMoreRecords: boolean;
    page: number;
    per_page: number;
    name: string;
    model: string;
    group: string;
    open: boolean;
    isViewTypeChanged: boolean;
    token: string | null;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class TaxesAccountListingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apigetUOMlistCallId!: string;
    apiDeleteCallId!: string;
    apiArchiveCallId!: string;
    apiUnarchiveCallId!: string;
    apiExportCallId!: string;
    apigetUOMFilterlistCallId!: string;

    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationMessage),

            // Customizable Area Start
            // Customizable Area End
        ];
        this.state = {
            // Customizable Area Start
            token: "",
            filterLabel: "",
            loading: false,
            searchKeyword: '',
            totalCounts: 0,
            totalPage: 0,
            globalCheck: false,
            checkedArr: [],
            active: [],
            activeGroup: '',
            activeFilter: -1,
            activeFilterArr: [],
            showFilter: false,
            showColumn: false,
            showGroup: false,
            deleteDialog: false,
            deleteDialogTable: false,
            showFav: false,
            showCurrentSearch: false,
            showAddToDashboard: false,
            deleteTableId: "",
            showSettings: false,
            showdata: [],
            groupbyResponse: [],
            searchValue: "",
            groupValue: "",
            groupByvalue: "",
            searchArray: [],
            defaultView: true,
            remainingItems: 0,
            isLoadMore: false,
            loadMoreRecords: false,
            page: 1,
            per_page: 9,
            name: '',
            model: '',
            group: '',
            open: false,
            isViewTypeChanged: true,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start


        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.setState({ ...this.state, loading: false });
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {

                if (apiRequestCallId === this.apigetUOMlistCallId) {
                    this.apiResponseUOM(responseJson)
                }
                if (apiRequestCallId === this.apigetUOMFilterlistCallId) {
                    this.apiResponseUOM(responseJson)
                }
                if (apiRequestCallId === this.apiExportCallId) {
                    this.apiResponseDownload(responseJson)
                }
                if (apiRequestCallId === this.apiDeleteCallId || apiRequestCallId === this.apiArchiveCallId || apiRequestCallId === this.apiUnarchiveCallId) {
                    toast.success(responseJson.message);
                    this.setState({ ...this.state, globalCheck: false, checkedArr: [], deleteDialog: false, deleteDialogTable: false });
                    this.getUOMFilterlistApi('', '')
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    selectedListItem = (value: any) => {
        if (this.state.activeFilterArr.includes(value)) {
            return 'selected-listItem'
        } else {
            return ''
        }
    }

    searchPlaceholderFn = () => {
        return !(this.state.searchArray.length) ? 'Search here...' : '';
    }

    searchIconDisplayFn = () => {
        return this.state.activeFilterArr.length && this.state.activeGroup ? 'display-none' : ''
    }

    checkGroupStatus = (length: any) => {
        return (!length) ? 'display-none' : ''
    }
    checkActiveGroupStatus = () => {
        return this.state.activeGroup ? 'display-none' : ''
    }

    loaderBoxFn = () => {
        if (this.state.loading || this.state.showdata.length == 0) {
            return 'loaderBox'
        } else {
            return 'display-none'
        }
    }

    loaderBoxContentFn = () => {
        if (this.state.loading) {
            return <CircularProgress />
        } else if (this.state.showdata.length == 0) {
            return <Typography className='bold-text'>No Records Found!</Typography>
        } else {
            return <></>
        }
    }

    listViewFn = () => {
        return (!this.state.isViewTypeChanged || this.state.loading || this.state.showdata.length == 0) ? 'display-none' : '';
    }

    kanbanStyle = () => {
        return (this.state.isViewTypeChanged || this.state.loading) ? 'display-none' : '';
    };

    handleGlobalCheckUOM = () => {
        let arr: any = [];
        this.state.showdata?.forEach((user: any) => {
            arr.push(user.id)
        })
        this.setState({ ...this.state, checkedArr: !this.state.globalCheck ? arr : [], globalCheck: !this.state.globalCheck })
    }

    handleUserCheckboxUOM = (id: any) => {
        if (this.state.checkedArr.includes(id)) {
            this.setState({ ...this.state, globalCheck: false, checkedArr: this.state.checkedArr.filter((i) => i !== id) })
        } else {
            this.setState({ ...this.state, globalCheck: (this.state.showdata.length == (this.state.checkedArr.length + 1)) ? true : false, checkedArr: [...this.state.checkedArr, id] })
        }
    }

    removeActiveFilter = (value: any) => {
        const filteredNumbers = this.state.activeFilterArr.filter(number => number !== value);
        const sarr = this.state.searchArray.filter(i => i.value != value);
        this.setState({ ...this.state, searchArray: sarr, activeFilterArr: filteredNumbers });
    }

    async componentDidMount() {
        const getToken = await StorageProvider.get("TOKEN")
        this.setState({
            token: getToken
        })
        await this.getUOMlistApi(this.state.per_page, this.state.page)
    }

    async componentDidUpdate(prevProps: {}, prevState: S) {
        if ((prevState.searchKeyword !== this.state.searchKeyword)) {
            if (this.state.searchKeyword.length >= 3) {
                await this.getUOMlistApi(this.state.per_page, 1);
            }
            if (this.state.searchKeyword.length == 0) {
                await this.getUOMlistApi(this.state.per_page, 1);
            }
        }
        if ((prevState.activeFilterArr.length !== this.state.activeFilterArr.length)) {
            await this.getUOMFilterlistApi('', '');
        }

        if ((prevState.page !== this.state.page) || (prevState.per_page !== this.state.per_page)) {
            this.state.token && await this.getUOMlistApi(this.state.per_page, this.state.page)
        }
    }

    chipLabel = (value: any) => {
        return value == 0 ? 'Archive' : value;
    }

    handleActiveFilter = (value: any) => {
        !(this.state.searchArray.filter((i) => i.value == value).length) && this.setState({ ...this.state, searchArray: [...this.state.searchArray, { type: 'filter', value: value }], activeFilter: value, activeFilterArr: [...this.state.activeFilterArr, value], showFilter: false });
    }

    handleActiveGroup = (value: any) => {
        if (!value) {
            const sarr = this.state.searchArray.filter(i => i.type != 'group');
            this.setState({ ...this.state, searchArray: sarr, activeGroup: '', showGroup: false })
        }
        else {
            !(this.state.searchArray.filter((i) => i.value == value).length) && this.setState({ ...this.state, searchArray: [...this.state.searchArray, { type: 'group', value: value }], activeGroup: value, showGroup: false });
        }
    }

    loadMoreRecords = async (action: number, type: string = '', lmore: boolean = false) => {
        this.setState({
            page: action == 0 ? 1 : this.state.page + action,
            loadMoreRecords: lmore
        })
    }

    handlePagination = async (action: number) => {
        this.setState({
            per_page: action, page: 1
        })
    }

    isThisLastPage(): boolean {
        if (!this.state.showdata.length) {
            return true;
        }
        if (!this.state.activeGroup) {
            return this.state.page === this.state.totalPage;
        }
        return true;
    }

    apiResponseDownload = (responseJson: { download_link: string; error: any[]; }) => {
        if (responseJson.download_link) {
            const anchor = document.createElement('a');
            anchor.href = responseJson.download_link;
            anchor.download = '';
            document.body.appendChild(anchor);
            anchor.click();
            toast.success("Downloaded Successfully");
            this.setState({ ...this.state, globalCheck: false, checkedArr: [], deleteDialog: false });
            this.getUOMFilterlistApi('', '')
            document.body.removeChild(anchor);
        } else if (responseJson.error) {
            toast.error(responseJson.error[0] || 'An unexpected error occurred');
        } else {
            return
        }
    };

    navigatetoTaxes = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'AddTaxAccount'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        this.send(message);
    }

    handleFilter = () => {
        this.setState({ ...this.state, showFilter: !this.state.showFilter });
    };
    handleFavorites = () => {
        this.setState({ ...this.state, showFav: !this.state.showFav });
    };
    handleGroup = () => {
        this.setState({ ...this.state, showGroup: !this.state.showGroup });
    };


    handleSettings = () => {
        this.setState({ ...this.state, showSettings: !this.state.showSettings });
    };

    apiResponseUOM = (responseJson: any) => {
        if (responseJson.data) {
            (this.state.loadMoreRecords && this.state.showdata?.length > 0) ? this.setState({ loadMoreRecords: false, showdata: [...this.state.showdata, ...responseJson.data], totalCounts: responseJson?.meta?.total_counts, totalPage: responseJson?.meta?.total_pages }) : this.setState({ loadMoreRecords: false, showdata: responseJson.data, totalCounts: responseJson?.meta?.total_counts, totalPage: responseJson?.meta?.total_pages });
        } else if (responseJson.errors) {
            window.alert("Invalid Token")
        }
    }
    handleDelete = () => {
        this.setState({
            deleteDialog: true,
        })
    };
    handleDialogCancel = () => {
        this.setState({
            deleteDialog: false,
        })
    }
    handleDeleteTable = () => {
        this.setState({
            deleteDialogTable: true,
        })
    };
    handleDialogCancelTable = () => {
        this.setState({
            deleteDialogTable: false,
        })
    }
    OnDelete = async () => {
        const body = {
            vendor_tax_ids: `[${this.state.checkedArr.join(',')}]`
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        this.apiDeleteCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteUrl}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'DELETE'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    OnDeleteTableData = async () => {
        const id = this.state.deleteTableId;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        this.apiDeleteCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.singleDeleteUrl}${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'DELETE'
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleArchive = async () => {
        this.setState({ ...this.state, showSettings: false })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const body = {
            vendor_tax_ids: `[${this.state.checkedArr.join(',')}]`
        }
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        this.apiArchiveCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.archiveUrl}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'PATCH'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleExport = () => {
        const body = {
            vendor_tax_ids: `[${this.state.checkedArr.join(',')}]`
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        this.apiExportCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.exportUrl}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'POST'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    checkValue() {
        return this.state.searchArray.some((item: { value: number | undefined; }) => item.value === 0);
    }

    renderKanbanViewType = (attributes: { for_customer: boolean, for_vendor: boolean }) => {
        if (attributes.for_customer) {
            return "Customer"
        } else
            return ""
    }

    renderKanbanViewTypeVendor = (attributes: { for_customer: boolean, for_vendor: boolean }) => {
        if (attributes.for_vendor) {
            return "Vendor"
        } else
            return ""
    }

    handleLoadMore = () => {
        const { totalCounts, per_page ,remainingItems} = this.state;
        const leftOver = totalCounts - per_page;
        const newRowsPerPage = per_page + 17;
        this.setState({ remainingItems: leftOver, isLoadMore: true, per_page: newRowsPerPage })

        if (leftOver > 0 && remainingItems > 0) {
            this.setState({
                remainingItems: leftOver,
                isLoadMore: true,
                per_page: newRowsPerPage,
            });
        }
    }
    handleUnarchive = () => {
        this.setState({ ...this.state, showSettings: false })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        const body = {
            vendor_tax_ids: `[${this.state.checkedArr.join(',')}]`
        }

        this.apiUnarchiveCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.unArchiveUrl}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'PATCH'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getUOMlistApi = async (per_page: number, page1: number) => {
        this.setState({ ...this.state, loading: true, globalCheck: false, checkedArr: [], page: page1 });
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };

        this.apigetUOMlistCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.listingUrl}?per_page=${per_page}&page=${page1}&search=${this.state.searchKeyword}${this.state.activeFilterArr.includes(0) ? '&archive=true' : ''}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getUOMFilterlistApi = async (key: string, value: string) => {

        this.setState({ ...this.state, loading: true, globalCheck: false, checkedArr: [], page: 1, searchKeyword: '' });
        const url_params = `?per_page=${this.state.per_page}&page=1${this.state.activeFilterArr.includes(0) ? '&archive=true' : ''}${this.state.activeGroup == 'Parent Category' ? '&parent_category=parent_category' : ''}`;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.token
        };
        this.apigetUOMFilterlistCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.listingUrl}${url_params}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    expandMore = () => {
        return <img className='expandMoreImg' height={7} src={paginationarrow} />;
    }

    getTableConditionValue = (value: boolean) => {
        if (value)
            return "Yes"
        else
            return "No"
    }

    expandTableRow = (user: any, classes: any) => {
        return <TableRow>
            <TableCell style={{ width: "92px", display: "flex" }}>
                <Checkbox
                    checked={this.state.checkedArr.includes(user?.id) ? true : false}
                    className={classes.userlistviewCheck}
                    onChange={() => this.handleUserCheckboxUOM(user?.id)}
                    data-test-id="usercheckboxUOM"
                />
            </TableCell>
            <TableCell className={classes.userlistviewTabletext}
                onClick={
                    () => { this.props.navigation.navigate("AddTaxAccounts", { id: user?.id }) }
                }
            >
                {user.attributes?.name}
            </TableCell>
            <TableCell className={classes.userlistviewTabletext} onClick={
                () => { this.props.navigation.navigate("AddTaxAccounts", { id: user?.id }) }
            }>
                {this.getTableConditionValue(user.attributes?.for_customer)}
            </TableCell>
            <TableCell className={classes.userlistviewTabletext} onClick={
                () => { this.props.navigation.navigate("AddTaxAccounts", { id: user?.id }) }
            }>
                {this.getTableConditionValue(user.attributes?.for_vendor)}
            </TableCell>
            <TableCell className={classes.userlistviewTabletext}>
                <Grid item sm={1} data-test-id="del-row">
                    <img onClick={() => {
                        this.setState({
                            deleteDialogTable: true,
                            deleteTableId: user?.id
                        })
                    }
                    } src={delrow} />
                </Grid>
            </TableCell>
        </TableRow>
    }

    // Customizable Area End
}
